import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TextField from "material-ui/TextField";
import CircularProgress from "material-ui/CircularProgress";
import Snackbar from "material-ui/Snackbar";
import Button from "../components/Button";
import { validEmail } from "../utils/validation";

import "../styles/PasswordReset.css";
import * as userActions from "../actions/userActions";

class ClaimAccount extends Component {
  state = {
    email: "",
    emailErrorText: ""
  };

  validateAndSend = () => {
    const { email } = this.state;

    if (!validEmail(email)) {
      this.setState({ emailErrorText: "Invalid email." });
    } else {
      this.sendClaim();
    }
  };

  sendClaim = async () => {
    const { email } = this.state;
    this.props.claimAccount(email);
  };

  render() {
    return (
      <div className="password-reset-wrapper">
        <h1>Aktivera KarnePLAY konto</h1>
        <p>
          Ange emailen du använde som karnevalist så skickar vi dig en länk,
          därefter får du välja ett lösenord!
        </p>
        <TextField
          onChange={(evt, val) => this.setState({ email: val })}
          onFocus={() => this.setState({ emailErrorText: "" })}
          errorText={this.state.emailErrorText}
          floatingLabelText="E-mail"
          fullWidth
        />
        {this.props.loading ? (
          <CircularProgress size={40} />
        ) : (
          <Button
            style={{ marginLeft: 0, marginTop: 40, width: "100%" }}
            medium
            rounded
            type="tertiary"
            title="Aktivera karnePLAY!"
            onClick={this.validateAndSend}
          />
        )}
        <Snackbar
          message={this.props.message || ""}
          open={!!this.props.error || !!this.props.mailSent}
          autoHideDuration={4000}
        />
      </div>
    );
  }
}

ClaimAccount.propTypes = {
  mailSent: PropTypes.bool,
  claimAccount: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  message: PropTypes.string
};

ClaimAccount.defaultProps = {
  error: "",
  message: "",
  loading: false,
  mailSent: false
};

function mapStateToProps(state) {
  return {
    error: state.user.error,
    loading: state.user.loading,
    message: state.user.message,
    mailSent: state.user.mailSent
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimAccount);
