import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Snackbar from "material-ui/Snackbar";
import IconButton from "material-ui/IconButton";
import BackIcon from "material-ui/svg-icons/navigation/arrow-back";
import * as movieActions from "../actions/movieActions";
import VideoPlayer from "../components/VideoPlayer";

import "../styles/video-react.css"; // import css
import "../styles/Play.css";

class Play extends Component {
  state = {
    hasUserActivity: true
  };

  componentWillMount() {
    const movie_title = this.props.match.params.movie_title;
    const movies = JSON.parse(sessionStorage.getItem("movies"));
    const currMovie = movies.filter(m => m.title.S === movie_title)[0];
    console.log("Movie:", movie_title, movies);
    window.scrollTo(0, 0);

    this.props.getSignedURL(currMovie && currMovie.title.S);
  }

  // componentDidMount () {
  // this.refs.player.subscribeToStateChange(this.handleStateChange.bind(this))
  // }

  // handleStateChange (state, prevState) {
  //   // copy player state to this component's state
  //   this.setState({
  //     player: state,
  //   })
  // }

  handleUserActivity = hasUserActivity => {
    this.setState({ hasUserActivity });
  };

  render() {
    // surl = signed url.
    const source = this.props.surl ? (
      <source src={this.props.surl} type="video/mp4" />
    ) : null;
    const player = <video controls>{source}</video> || (
      <VideoPlayer
        ref="player"
        onUserActivity={this.handleUserActivity}
        src={this.props.surl || null}
      />
    );

    const backbutton = (
      <IconButton
        iconStyle={{
          color: "white",
          backgroundColor: "rgba(30,30,30,0.5)",
          borderRadius: "50px",
          width: 50,
          height: 50
        }}
        style={{
          position: "absolute",
          top: "10px",
          left: "10px"
        }}
        onClick={() => window.history.back()}
      >
        <BackIcon />
      </IconButton>
    );

    return (
      <div className="player-wrapper">
        {player}
        {backbutton}
        <Snackbar
          open={!!this.props.url_err_payload}
          message={this.props.url_err_message || ""}
          autoHideDuration={1000}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    accessToken: state.user.accessToken,
    error: state.user.error,
    loading: state.user.loading,
    movies: state.movie.movies,
    surl: state.movie.signedurl,
    url_err_message: state.movie.url_err_message,
    url_err_payload: state.movie.url_err_payload,
    detail: state.movie.moviedetail
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...movieActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Play);
