/* global localStorage */
import * as types from "../constants";
import * as API from "./api";

/* eslint-disable import/prefer-default-export */
export function register(user) {
  return dispatch => {
    dispatch({
      type: types.REGISTER_USER
    });
    API.registerUser(user, (err, res) => {
      if (err) {
        // Server error
        return dispatch({
          type: types.REGISTER_USER_FAIL,
          payload: { error: err }
        });
      }

      const payload = JSON.parse(res.Payload);
      if (!payload.created) {
        // User not created
        return dispatch({
          type: types.REGISTER_USER_FAIL,
          payload: { error: payload.message }
        });
      }

      // User created
      return dispatch({
        type: types.REGISTER_USER_SUCCESS,
        payload: {
          ...res,
          user
        }
      });
    });
  };
}
export function verifyEmail(email, token) {
  return dispatch => {
    dispatch({
      type: types.VERIFY_USER
    });
    API.verifyEmail(email, token, (err, res) => {
      if (err) {
        // Server error
        return dispatch({
          type: types.VERIFY_USER_FAIL,
          payload: { error: err }
        });
      }
      const payload = JSON.parse(res.Payload);
      if (payload.verified) {
        // User verified
        return dispatch({
          type: types.VERIFY_USER_SUCCESS,
          payload: {
            email
          }
        });
      }
      // User not verified
      return dispatch({
        type: types.VERIFY_USER_FAIL,
        payload: { error: payload.errorMessage }
      });
    });
  };
}

export function login(user) {
  return async dispatch => {
    dispatch({
      type: types.LOGIN
    });

    API.login(user, (err, res) => {
      if (err || res.StatusCode !== 200) {
        return dispatch({
          type: types.LOGIN_FAIL,
          payload: `Server error: ${err}`
        });
      }
      const payload = JSON.parse(res.Payload);
      if (payload.login) {
        const group = payload.group.L.map(g => g.S);
        const userInfo = {
          email: payload.email,
          group,
          identityid: payload.identityId
        };
        return dispatch({
          type: types.LOGIN_SUCCESS,
          payload: {
            accessToken: payload.token,
            user: userInfo
          }
        });
      }
      return dispatch({
        type: types.LOGIN_FAIL,
        payload
      });
    });
  };
}
export function verifyPremium(code) {
  return async dispatch => {
    dispatch({
      type: types.VERIFY_PREMIUM
    });
    const email = localStorage.getItem("email");
    API.verifyPremium(email, code, (err, res) => {
      if (err || res.StatusCode !== 200) {
        return dispatch({
          type: types.VERIFY_PREMIUM_FAIL,
          payload: `Server error: ${err}`
        });
      }
      const payload = JSON.parse(res.Payload);
      if (payload.errorMessage) {
        return dispatch({
          type: types.VERIFY_PREMIUM_FAIL,
          payload: payload.errorMessage
        });
      }
      return dispatch({
        type: types.VERIFY_PREMIUM_SUCCESS,
        payload: {
          success: true
        }
      });
    });
  };
}
export function forgotPassword(email) {
  return async dispatch => {
    dispatch({
      type: types.FORGOT_PASSWORD
    });
    API.forgotPassword(email, (err, res) => {
      if (err || res.StatusCode !== 200) {
        return dispatch({
          type: types.FORGOT_PASSWORD_FAIL,
          payload: `Server error: ${err}`
        });
      }
      const payload = JSON.parse(res.Payload);
      if (payload.errorMessage) {
        return dispatch({
          type: types.FORGOT_PASSWORD_FAIL,
          payload: payload.errorMessage
        });
      }
      return dispatch({
        type: types.FORGOT_PASSWORD_SUCCESS,
        payload: {
          success: true
        }
      });
    });
  };
}

export function claimAccount(email) {
  return async dispatch => {
    dispatch({
      type: types.FORGOT_PASSWORD
    });
    API.claimAccount(email, (err, res) => {
      if (err || res.StatusCode !== 200) {
        return dispatch({
          type: types.FORGOT_PASSWORD_FAIL,
          payload: `Server error: ${err}`
        });
      }
      const payload = JSON.parse(res.Payload);
      if (payload.errorMessage) {
        return dispatch({
          type: types.FORGOT_PASSWORD_FAIL,
          payload: payload.errorMessage
        });
      }
      return dispatch({
        type: types.FORGOT_PASSWORD_SUCCESS,
        payload: {
          success: true
        }
      });
    });
  };
}
export function verifyKarnevalist(email, password, token) {
  return async dispatch => {
    dispatch({
      type: types.RESET_PASSWORD
    });
    API.verifyKarnevalist(email, password, token, (err, res) => {
      if (err || res.StatusCode !== 200) {
        return dispatch({
          type: types.RESET_PASSWORD_FAIL,
          payload: `Server error: ${err}`
        });
      }
      const payload = JSON.parse(res.Payload);
      if (!payload.changed) {
        return dispatch({
          type: types.VERIFY_KARNEVALIST_FAIL,
          payload: "Account already verified."
        });
      }
      return dispatch({
        type: types.RESET_PASSWORD_SUCCESS,
        payload: {
          success: true
        }
      });
    });
  };
}

export function resetPassword(email, password, token) {
  return async dispatch => {
    dispatch({
      type: types.RESET_PASSWORD
    });
    API.resetPassword(email, password, token, (err, res) => {
      if (err || res.StatusCode !== 200) {
        return dispatch({
          type: types.RESET_PASSWORD_FAIL,
          payload: `Server error: ${err}`
        });
      }
      const payload = JSON.parse(res.Payload);
      if (payload.errorMessage) {
        return dispatch({
          type: types.RESET_PASSWORD_FAIL,
          payload: payload.errorMessage
        });
      }
      return dispatch({
        type: types.RESET_PASSWORD_SUCCESS,
        payload: {
          success: true
        }
      });
    });
  };
}

export const logout = () => ({ type: types.LOGOUT });
