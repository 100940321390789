import React, { Component } from "react";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import { reduxForm } from "redux-form";
// import PropTypes from "prop-types";
// import MediaQuery from "react-responsive";
// import Snackbar from "material-ui/Snackbar";
// import Button from "../components/Button";
// import * as userActions from "../actions/userActions";
// import * as uiActions from "../actions/uiActions";
// import * as validation from "../utils/validation";

import "../styles/Settings.css";

class Settings extends Component {
  render() {
    return (
      <div>
        <h1>Settings...</h1>
      </div>
    );
  }
}

export default Settings;
