import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers";

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  compose(applyMiddleware(thunk))
);
/* eslint-enable */

export default store;
