import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Snackbar from "material-ui/Snackbar";
import CircularProgress from "material-ui/CircularProgress";
import ReactCodeInput from "react-code-input";

import * as userActions from "../actions/userActions";
import Button from "../components/Button";

import "../styles/Premium.css";

class Premium extends Component {
  verifyCode() {
    this.props.verifyPremium(this.refs.code.state.value);
  }
  render() {
    console.log("bli premium");
    return (
      <div className="wrapper">
        <h1>Bli premium</h1>
        <ReactCodeInput ref="code" type="text" fields={16} />
        <Button
          title="Skicka in kod"
          onClick={() => this.verifyCode()}
          type={this.props.loading ? "disabled" : "tertiary"}
          className="login-button"
        />
        {this.props.loading && <CircularProgress style={{ marginTop: 20 }} />}
        <Snackbar
          open={!!this.props.premium && this.props.premium.success}
          message="Du har nu premium-access! Vänligen logga ut och in igen."
          autoHideDuration={5000}
        />
        <Snackbar
          open={!!this.props.error}
          message={this.props.error || ""}
          autoHideDuration={5000}
        />
      </div>
    );
  }
}

Premium.propTypes = { verifyPremium: PropTypes.func.isRequired };

function mapStateToProps(state) {
  return {
    loading: state.user.loading,
    premium: state.user.premium,
    error: state.user.error
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Premium);
