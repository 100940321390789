import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Karnevalist.css";

import logos from "./logos";
import sections from "../../sections.json";

const Sections = () => (

  <div className="row">
    <h1 className="head">Bildgallerier</h1>
    <div className="sections">
      {Object.entries(sections).map(item => (
        <div key={item[1]} className="headerStyle">
          <Link to={`/images/${item[1]}`}>
            <img className="logos" src={logos[item[0]]} alt={`${item[1]}`} />
          </Link>
          {item[1]}
        </div>
    ))}
    </div>
  </div>
);

export default Sections;
