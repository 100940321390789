/* global localStorage sessionStorage */
import * as types from "../constants";
import * as API from "./api";

export function getFiles() {
  return async dispatch => {
    dispatch({
      type: types.GET_FILES
    });
    try {
      API.getFiles((err, res) => {
        if (err || res.StatusCode !== 200) {
          dispatch({
            type: types.GET_FILES_FAIL,
            payload: {
              err
            }
          });
        } else {
          const payload = JSON.parse(res.Payload);
          if (payload.errorMessage) {
            return dispatch({
              type: types.GET_FILES_FAIL,
              payload: payload.errorMessage
            });
          }
          dispatch({
            type: types.GET_FILES_SUCCESS,
            payload: JSON.parse(res.Payload)
          });
        }
      });
    } catch (err) {
      dispatch({
        type: types.GET_FILES_FAIL,
        payload: { error: "Server error." }
      });
    }
  };
}
export function uploadFile(name, body) {
  return async dispatch => {
    dispatch({
      type: types.UPLOAD_FILE
    });
    try {
      API.uploadFile(name, body, (err, res) => {
        if (err || res.StatusCode !== 200) {
          dispatch({
            type: types.UPLOAD_FILE_FAIL,
            payload: {
              err
            }
          });
        } else {
          dispatch({
            type: types.UPLOAD_FILE_SUCCESS,
            payload: JSON.parse(res.Payload)
          });
        }
      });
    } catch (err) {
      dispatch({
        type: types.UPLOAD_FILE_FAIL,
        payload: { error: "Server error." }
      });
    }
  };
}
export function deleteFile(name) {
  return async dispatch => {
    dispatch({
      type: types.DELETE_FILE
    });
    try {
      API.deleteFile(name, (err, res) => {
        const payload = !err && JSON.parse(res.Payload);
        if (err || payload.errorMessage) {
          dispatch({
            type: types.DELETE_FILE_FAIL,
            payload: {
              error: err || payload.errorMessage
            }
          });
        } else {
          dispatch({
            type: types.DELETE_FILE_SUCCESS,
            payload: JSON.parse(res.Payload)
          });
        }
      });
    } catch (err) {
      dispatch({
        type: types.GET_FILES_FAIL,
        payload: { error: "Server error." }
      });
    }
  };
}
