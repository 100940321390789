import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import FlatButton from "material-ui/FlatButton";
import PlayIcon from "material-ui/svg-icons/av/play-arrow";

class Timer extends React.Component {
  calcRemain = () => Math.floor((this.props.downTo - new Date()) / 1000);
  state = { remainingTime: this.calcRemain() };
  componentDidMount() {
    this.interval = setInterval(
      () =>
        this.setState({
          remainingTime: this.calcRemain()
        }),
      500
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  formatSeconds(seconds) {
    const remainDays = Math.floor(seconds / (60 * 60 * 24));
    const remainHours = Math.floor(seconds / (60 * 60)) % 24;
    const remainMinutes = Math.floor(seconds / 60) % 60;
    const remainSeconds = seconds % 60;
    const zeroPadd = text => (text.toString().length < 2 ? `0${text}` : text);
    return parseInt(remainDays, 10) > 0
      ? `${remainDays} dagar`
      : `${zeroPadd(remainHours)}:${zeroPadd(remainMinutes)}:${zeroPadd(
          remainSeconds
        )}`;
  }
  render() {
    return (
      <div className="banner">
        <div className="timer">
          {this.state.remainingTime > 0 &&
            `Allt material släpps på årsdagen av Uppropet den 4 feb (om ${this.formatSeconds(
              this.state.remainingTime
            )}`}
          )
          <span>
            Nedan har ni en sneak-peak på årets karnevalsfilm - Drömmarnas Lund.
            Mycket nöje!
          </span>
        </div>
      </div>
    );
  }
}

const Title = ({ titleImage, title }) => (
  <h1
    style={{
      backgroundImage: `url(${titleImage})`,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      maxWidth: "500px",
      width: "50vw",
      height: "15vw",
      margin: "0 auto"
    }}
  >
    <span style={{ display: titleImage ? "none" : "inline" }}>{title}</span>
  </h1>
);

class DetailHeader extends Component {
  state = {
    currentBg: 0
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      let currentBg = this.state.currentBg + 1;

      if (currentBg >= this.props.headerImages.length) {
        currentBg = 0;
      }

      this.setState({ currentBg });
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { headerImages, title, titleImage } = this.props;

    if (!headerImages || (headerImages && headerImages.length <= 0)) {
      return <header className="header mainheader" />;
    }

    const currentImg = headerImages[this.state.currentBg];
    const playUrl = `/play/${encodeURI(title)}`;

    const group = localStorage.getItem("user_group");
    const isKarnevalist = group && group.toLowerCase().includes("karnevalist");

    return (
      <div>
        <header
          className="header mainheader"
          style={{
            backgroundImage: `url(${currentImg.S})`,
            backgroundSize: "cover",
            backgroundColor: this.props.backgroundColor,
            backgroundPosition: "center"
          }}
        >
          <div className="moviecontent">
            <Title title={title} titleImage={titleImage} />
            <Link to={playUrl}>
              <FlatButton
                backgroundColor="rgb(255, 64, 129)"
                style={{ color: "#fff" }}
                hoverColor="#000"
                label="Watch"
                labelPosition="before"
                primary
                icon={<PlayIcon color="#fff" />}
              />
            </Link>
          </div>
        </header>
      </div>
    );
  }
}

DetailHeader.propTypes = {
  title: PropTypes.string,
  headerImages: PropTypes.array,
  titleImage: PropTypes.string,
  backgroundColor: PropTypes.string
};

DetailHeader.defaultProps = {
  title: "",
  headerImages: [],
  titleImage: "",
  backgroundColor: ""
};

export default DetailHeader;
