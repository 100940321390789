import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Snackbar from "material-ui/Snackbar";
import CircularProgress from "material-ui/CircularProgress";

import * as userActions from "../actions/userActions";
import Button from "../components/Button";

import "../styles/Verify.css";

class Verify extends Component {
  componentWillMount() {
    const email = decodeURIComponent(this.props.match.params.email);
    const verifytoken = decodeURIComponent(this.props.match.params.verifytoken);
    this.props.verifyEmail(email, verifytoken);
  }

  render() {
    console.log("verifiering");
    const text = this.props.verified_email ? (
      <section>
        <p>
          <strong>{this.props.verified_email}</strong> has been verified.
        </p>
        <Link to="/login">
          <Button
            title="Logga in"
            type={this.props.loading ? "disabled" : "tertiary"}
            className="login-button"
          />
        </Link>
      </section>
    ) : (
      <section>
        <p>Verifying....</p>
      </section>
    );
    return (
      <div className="wrapper">
        <h1>Verifiering av epost</h1>
        {text}
        {this.props.loading && <CircularProgress style={{ marginTop: 20 }} />}
        <Snackbar
          open={!!this.props.error}
          message={this.props.error || ""}
          autoHideDuration={5000}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    verified_email: state.user.email,
    error: state.user.error
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Verify);
