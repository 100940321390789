import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tabs, Tab } from "material-ui/Tabs";
import CircularProgress from "material-ui/CircularProgress";

import * as movieActions from "../../actions/movieActions";
import DetailHeader from "./DetailHeader";
import RelatedMovies from "./RelatedMovies";
import About from "./About";

function convertStyleConfig(styleconfig = {}) {
  return {
    primaryColor: styleconfig.primaryColor && styleconfig.primaryColor.S,
    secondaryColor: styleconfig.secondaryColor && styleconfig.secondaryColor.S,
    headerImages: styleconfig.headerImages && styleconfig.headerImages.L,
    headerBackground:
      styleconfig.headerBackground && styleconfig.headerBackground.S,
    titleImage: styleconfig.titleImage && styleconfig.titleImage.S
  };
}

class MovieDetail extends Component {
  state = {
    currentTab: "related"
  };

  componentDidMount() {
    this.props.getMovies();
  }

  getRelatedMovies() {
    return (
      this.props.movies &&
      this.props.movies.filter(m => m.relatedMovie && m.relatedMovie.N)
    );
  }

  handleChange = value =>
    this.setState({
      currentTab: value
    });

  render() {
    const { match, movies = [] } = this.props;
    let movieId = (match && match.params.id) || this.props.movieId;
    if (movieId === "-1") {
      movieId =
        movies &&
        movies.reduce(
          (acc, curr, index) =>
            curr.default && curr.default.BOOL ? index : acc,
          0
        );
    }
    const movie =
      movies &&
      (movieId !== "-1"
        ? movies[movieId]
        : movies.filter(x => x.default && x.default.BOOL)[0]);

    if (!movie) {
      return (
        <div>
          <CircularProgress style={{ marginTop: "50px" }} />
          <p>Loading...</p>
        </div>
      );
    }

    const styleconfig = convertStyleConfig(
      movie.styleconfig && movie.styleconfig.M
    );
    const relatedMovies = this.getRelatedMovies(movieId);
    const hasRelated = relatedMovies && relatedMovies.length > 0;

    return (
      <div style={{ maxWidth: "1500px", display: "block", margin: "auto" }}>
        <DetailHeader
          headerImages={styleconfig.headerImages}
          titleImage={styleconfig.titleImage}
          backgroundColor={styleconfig.primaryColor}
          title={movie.title.S}
          id={movieId}
        />
        {hasRelated && (
          <Tabs
            value={this.state.currentTab}
            onChange={this.handleChange}
            style={{
              marginTop: -48
            }}
            tabItemContainerStyle={{
              backgroundColor: "transparent"
            }}
          >
            <Tab
              label="Extramaterial"
              value="related"
              style={{
                backgroundColor: "transparent"
              }}
            >
              <RelatedMovies relatedMovies={relatedMovies} />
            </Tab>
            <Tab
              label={`Om ${movie.title.S}`}
              value="about"
              style={{
                backgroundColor: "transparent"
              }}
            >
              <About
                title={movie.title.S}
                description={movie.description && movie.description.S}
              />
            </Tab>
          </Tabs>
        )}
        {!hasRelated && (
          <About
            title={movie.title.S}
            description={movie.description && movie.description.S}
          />
        )}
      </div>
    );
  }
}

MovieDetail.propTypes = {
  movies: PropTypes.array.isRequired,
  getMovies: PropTypes.func.isRequired,
  movieId: PropTypes.string
};
MovieDetail.defaultProps = {
  movieId: "-1"
};

function mapStateToProps(state) {
  return {
    loading: state.user.loading,
    movies: state.movie.movies,
    detail: state.movie.moviedetail
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...movieActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MovieDetail);
