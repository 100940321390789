import React from "react";
import "../../styles/App.css";
import "../../styles/Karnevalist.css";
import Header from "./Header";
import Sections from "./Sections";
import MovieGrid from "./MovieGrid";
import MovieDetail from "../MovieDetail";
import Programblad from "../../assets/programblad.png";
import Journal from "../../assets/journal.png";
import Sangbladet from "../../assets/sangbladet.png";
import Melodin from "../../assets/melodin.png";

const Karnevalist = () => (
  <div>
    <Header />
    <MovieGrid
      header="Inspelningar 2018"
      year={2018}
    />
    <MovieDetail />
    <hr />
    <Sections />
    <hr />
    <div className="invdiv">
      <h1 className="head center">KarneTryck</h1>
      <a className="pdflink" href="http://karnepdf.s3-website-eu-west-1.amazonaws.com/programbladet/" target="_blank" rel="noopener noreferrer">
        <img src={Programblad} alt="Programbladet" className="pdfimg" style={{ maxHeight: 300 }} />
      </a>
      <a className="pdflink" href="http://karnepdf.s3-website-eu-west-1.amazonaws.com/journalen/" target="_blank" rel="noopener noreferrer">
        <img alt="Journalen" src={Journal} className="pdfimg" style={{ maxHeight: 300 }} />
      </a>
      <a className="pdflink" href="http://karnepdf.s3-website-eu-west-1.amazonaws.com/melodin/" target="_blank" rel="noopener noreferrer">
        <img alt="Melodin" src={Melodin} className="pdfimg" style={{ maxHeight: 300 }} />
      </a>
      <a className="pdflink" href="http://karnepdf.s3-website-eu-west-1.amazonaws.com/sangbladet/" target="_blank" rel="noopener noreferrer">
        <img src={Sangbladet} alt="Sångbladet" className="pdfimg" style={{ maxHeight: 300 }} />
      </a>
    </div>
    <hr />
    <MovieGrid
      header="Inspelningar 2014"
      year={2014}
    />
    <hr />
  </div>
);
//  <MovieGrid
//    header="Alla karnevalsfilmer"
//  />

export default Karnevalist;
