import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TextField from "material-ui/TextField";
import CircularProgress from "material-ui/CircularProgress";
import Snackbar from "material-ui/Snackbar";
import Button from "../components/Button";
import { validEmail } from "../utils/validation";

import "../styles/PasswordReset.css";
import * as userActions from "../actions/userActions";

class ForgotPassword extends Component {
  state = {
    email: "",
    emailErrorText: ""
  };

  validateAndSend = () => {
    const { email } = this.state;

    if (!validEmail(email)) {
      this.setState({ emailErrorText: "Ogiltigt epost." });
    } else {
      this.sendReset();
    }
  };

  sendReset = async () => {
    const { email } = this.state;
    this.props.forgotPassword(email);
  };

  render() {
    return (
      <div className="password-reset-wrapper">
        <h1>Glömt lösenord</h1>
        <p>
          Ange din epost-adress och vi kommer att skicka dig en epost med
          instruktioner hur du återställer ditt lösenord. Type in your email and
          we&apos;ll email you instructions on how to create a new password.
        </p>
        <TextField
          onChange={(evt, val) => this.setState({ email: val })}
          onFocus={() =>
            this.setState({ emailErrorText: "", serverMessage: "" })
          }
          errorText={this.state.emailErrorText}
          floatingLabelText="E-post"
          fullWidth
        />
        {this.props.loading ? (
          <CircularProgress size={40} />
        ) : (
          <Button
            style={{ marginLeft: 0, marginTop: 40, width: "100%" }}
            medium
            rounded
            type="tertiary"
            title="Skicka återställnings-länk"
            onClick={this.validateAndSend}
          />
        )}
        <Snackbar
          message={this.props.message || ""}
          open={!!this.props.error || !!this.props.mailSent}
          autoHideDuration={4000}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.user.error,
    loading: state.user.loading,
    message: state.user.message,
    mailSent: state.user.mailSent
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
