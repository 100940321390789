/* global localStorage */

import * as types from "../constants";

const initialState = {
  user: null,
  loading: false,
  accessToken: null,
  error: null,
  registerErrors: [],
  registered: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.REHYDRATE_TOKEN:
      return {
        ...state,
        user: action.payload.email
          ? { ...state.user, email: action.payload.email }
          : null,
        accessToken: action.payload.accessToken
      };

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    case types.CLEAR_MESSAGE:
      return {
        ...state,
        message: null
      };

    case types.REGISTER_USER:
      return {
        ...state,
        registered: false,
        loading: true
      };

    case types.REGISTER_USER_SUCCESS:
      return {
        ...state,
        error: null,
        registered: true,
        loading: false
      };

    case types.REGISTER_USER_FAIL:
      return {
        ...state,
        registered: false,
        error: action.payload.error,
        loading: false
      };

    case types.LOGOUT:
      // Reducers shouldn't have side effects... migrate to redux-persist later
      localStorage.removeItem("email");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("identityid");
      localStorage.removeItem("user_group");
      localStorage.removeItem("login_expires");
      return {
        ...state,
        accessToken: null,
        user: null
      };

    case types.LOGIN:
      return {
        ...state,
        loading: true,
        resetSuccess: false
      };

    case types.LOGIN_SUCCESS: {
      const expireTime = new Date(
        new Date().getTime() + 24 * 60 * 60 * 1000
      ).getTime();
      console.log(expireTime);

      // Reducers shouldn't have side effects... migrate to redux-persist later
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("email", action.payload.user.email);
      localStorage.setItem("identityid", action.payload.user.identityid);
      localStorage.setItem("user_group", action.payload.user.group);
      localStorage.setItem("login_expires", expireTime);

      return {
        ...state,
        loading: false,
        // temp workaround for not receiving a user at login and having
        // to specify the user email when retrieving the logged in user (/api/user/{email})
        user: action.payload.user,
        accessToken: action.payload.accessToken
      };
    }

    case types.LOGIN_FAIL: {
      const { verified } = action.payload;
      const error =
        verified === undefined
          ? "Fel inloggningsuppgifter"
          : "Kontot ej verifierat";
      return {
        ...state,
        loading: false,
        accessToken: null,
        error
      };
    }

    case types.VERIFY_USER: {
      return {
        ...state,
        loading: true
      };
    }

    case types.VERIFY_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        email: action.payload.email
      };
    }

    case types.VERIFY_USER_FAIL: {
      return {
        ...state,
        loading: false,
        error: `Failed to verify user: ${action.payload.error}`
      };
    }

    case types.GET_USER: {
      return {
        ...state,
        loading: true
      };
    }

    case types.GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload
      };
    }

    case types.GET_USER_FAIL: {
      return {
        ...state,
        loading: false,
        user: null,
        error: "Failed to retrieve user"
      };
    }

    case types.SET_USER: {
      return {
        ...state,
        loading: true
      };
    }

    case types.SET_USER_SUCCESS: {
      const userData = { ...action.payload.userData };
      const newUser = { ...state.user };

      Object.keys(userData).forEach(key => (newUser[key] = userData[key])); //eslint-disable-line

      return {
        ...state,
        loading: false,
        user: newUser,
        message: action.payload.message,
        success: action.payload.success
      };
    }

    case types.SET_USER_FAIL: {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        success: action.payload.success
      };
    }

    case types.FORGOT_PASSWORD: {
      return {
        ...state,
        loading: true,
        error: null,
        mailSent: false,
        message: null
      };
    }

    case types.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        mailSent: true,
        message: "Ett mail har skickats."
      };
    }

    case types.FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        mailSent: false,
        error: true,
        message: "Ett fel skedde."
      };
    }

    case types.RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
        error: null,
        mailSent: false,
        message: null
      };
    }

    case types.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        resetSuccess: true,
        message: "Lösenord återställt."
      };
    }

    case types.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        resetSuccess: false,
        error: true,
        message: "Ett fel skedde."
      };
    }

    case types.VERIFY_KARNEVALIST_FAIL: {
      return {
        ...state,
        loading: false,
        resetSuccess: false,
        error: true,
        message: "Kontot har redan verifierats."
      };
    }

    case types.VERIFY_PREMIUM: {
      return {
        ...state,
        loading: true,
        error: null,
        premium: null
      };
    }

    case types.VERIFY_PREMIUM_SUCCESS: {
      const group = localStorage.getItem("user_group");
      localStorage.setItem("user_group", `${group},premium`);
      return {
        ...state,
        loading: false,
        premium: action.payload,
        error: null
      };
    }

    case types.VERIFY_PREMIUM_FAIL: {
      return {
        ...state,
        loading: false,
        premium: null,
        error: "Premiumkod ej giltig"
      };
    }

    default:
      return state;
  }
}
