import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Snackbar from "material-ui/Snackbar";
import CircularProgress from "material-ui/CircularProgress";
import { ListItem } from "material-ui/List";
import FileFolder from "material-ui/svg-icons/file/folder";
import Avatar from "material-ui/Avatar";
import Delete from "material-ui/svg-icons/action/delete";
import Done from "material-ui/svg-icons/action/done";

import Button from "../components/Button";
import * as fileActions from "../actions/fileActions";

function humanFileSize(bytes, si) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return `${bytes.toFixed(1)} ${units[u]}`;
}

class AdminFile extends Component {
  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }

  componentWillMount() {
    this.props.getFiles();
  }
  componentWillReceiveProps(props) {
    if (props.delete_success || props.upload_success) {
      this.props.getFiles();
    }
  }

  deleteFile(idx) {
    const file = this.props.files[idx];
    if (window.confirm(`Are you sure you want to delete ${file.Key}?`)) {
      this.props.deleteFile(file.Key);
    }
  }
  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    this.fileUpload(this.state.file);
  }

  fileUpload(file) {
    const uploader = (name, body) => {
      this.props.uploadFile(name, body);
    };
    uploader.bind(this);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = error => {
      alert("Error: ", error);
    };
    reader.onload = () => {
      uploader(file.name, reader.result.split(",")[1]);
    };
  }

  render() {
    return (
      <div className="left wrapper">
        <h1 style={{ color: "#8a4797", fontSize: 60 }}>Manage Files</h1>
        <Link to="/admin/movies">
          <Button title="Manage movies" type="tertiary" />
        </Link>
        <hr />

        {this.props.loading && <CircularProgress style={{ marginTop: 20 }} />}
        {this.props.upload_success ||
          (this.props.delete_success && <Done style={{ marginTop: 20 }} />)}
        <Snackbar
          open={!!this.props.error}
          message={this.props.error || ""}
          autoHideDuration={4000}
          onRequestClose={this.props.clearError}
        />
        <form onSubmit={this.onFormSubmit}>
          <h3>File Upload</h3>
          <input
            type="file"
            onChange={e => this.setState({ file: e.target.files[0] })}
          />
          <button type="submit">Upload</button>
        </form>
        <hr />

        {this.props.files &&
          this.props.files.map((f, idx) => (
            <ListItem
              key={`KarneFiles-${idx}`}
              leftAvatar={<Avatar icon={<FileFolder />} />}
              rightIcon={<Delete onClick={() => this.deleteFile(idx)} />}
              primaryText={f.Key}
              secondaryText={humanFileSize(f.Size, true)}
            />
          ))}
      </div>
    );
  }
}

AdminFile.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool
};

AdminFile.defaultProps = {
  error: "",
  loading: false
};

function mapStateToProps(state) {
  return {
    files: state.file.files,
    error: state.file.error,
    loading: state.file.loading,
    upload_success: state.file.upload_success,
    delete_success: state.file.delete_success
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...fileActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminFile);
