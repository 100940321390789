import React from "react";
import PropTypes from "prop-types";
import MovieImage from "../../components/MovieImage";

const STYLE = {
  textAlign: "center"
};

function RelatedMovies({ relatedMovies }) {
  const movieList =
    relatedMovies &&
    relatedMovies.map((m, i) => (
      <MovieImage movieIndex={i} movie={m} key={m.title.S} />
    ));

  return <div style={STYLE}>{movieList}</div>;
}

RelatedMovies.defaultProps = {};

RelatedMovies.propTypes = {
  relatedMovies: PropTypes.array.isRequired
};

export default RelatedMovies;
