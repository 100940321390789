import React, { Component } from "react";
import PropTypes from "prop-types";

const propTypes = {
  autoPlay: PropTypes.bool,
  src: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onUserActivity: PropTypes.func.isRequired
};

const defaultProps = {
  autoPlay: true,
  controls: false
};

class Video extends Component {
  componentWillUnmount() {
    if (this.player) {
      this.player.src = "";
      this.player.load();
    }
  }

  setVideo = el => {
    this.player = el;
    if (this.player) {
      this.player.play();
    }
  };

  handleUserActivity = () => {
    if (this.player && !this.player.hasAttribute("controls")) {
      this.player.setAttribute("controls", "controls");
    }

    this.props.onUserActivity && this.props.onUserActivity(true);

    clearTimeout(this.timer);
    this.timeout = setTimeout(() => {
      if (this.player) {
        this.player.removeAttribute("controls");
        this.props.onUserActivity && this.props.onUserActivity(false);
      } else {
        this.props.onUserActivity && this.props.onUserActivity(true);
      }
    }, 4000);
  };

  render() {
    const { autoPlay = true, src, onError } = this.props;

    return (
      <video
        ref={this.setVideo}
        autoPlay={autoPlay}
        id="displayVideo"
        onError={onError}
        src={src}
        style={{
          width: "100vw",
          maxHeight: "100vh"
        }}
        onMouseMove={this.handleUserActivity}
      />
    );
  }
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;
