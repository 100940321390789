/* global localStorage */

import * as types from "../constants";

const initialState = {
  files: [],
  loading: false,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_FILES: {
      return {
        ...state,
        loading: true,
        upload_success: false,
        delete_success: false,
        error: null
      };
    }
    case types.GET_FILES_SUCCESS: {
      return {
        ...state,
        loading: false,
        files: JSON.parse(action.payload),
        error: null
      };
    }
    case types.GET_FILES_FAIL: {
      return {
        ...state,
        loading: false,
        files: null,
        error: "Failed to retrieve files",
        errorMessage: action.payload
      };
    }
    case types.UPLOAD_FILE: {
      return {
        ...state,
        loading: true,
        upload_success: false,
        error: null
      };
    }
    case types.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        upload_success: true,
        error: null
      };
    }
    case types.UPLOAD_FILE_FAIL: {
      return {
        ...state,
        loading: false,
        upload_success: false,
        error: "Failed to update file"
      };
    }

    case types.DELETE_FILE: {
      return {
        ...state,
        loading: true,
        delete_success: false,
        error: null
      };
    }
    case types.DELETE_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        delete_success: true,
        error: null
      };
    }
    case types.DELETE_FILE_FAIL: {
      return {
        ...state,
        loading: false,
        delete_success: false,
        error: "Failed to update file"
      };
    }
    default:
      return state;
  }
}
