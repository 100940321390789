import administerit from "../../assets/sektionsloggor/administerit.png";
import barnevalen from "../../assets/sektionsloggor/barnevalen.png";
import biljonsen from "../../assets/sektionsloggor/biljonsen.png";
import bladderiet from "../../assets/sektionsloggor/bladderiet.png";
import dansen from "../../assets/sektionsloggor/dansen.png";
import drommerian from "../../assets/sektionsloggor/drommerian.png";
import ekonomi from "../../assets/sektionsloggor/ekonomi.png";
import filmen from "../../assets/sektionsloggor/filmen.png";
import komsek from "../../assets/sektionsloggor/komsek.png";
import krogarna from "../../assets/sektionsloggor/krogarna.png";
import krogeriet from "../../assets/sektionsloggor/krogeriet.png";
import mediahuset from "../../assets/sektionsloggor/mediahuset.png";
import musiksektionen from "../../assets/sektionsloggor/musiksektionen.png";
import nojen from "../../assets/sektionsloggor/nojen.png";
import omrade from "../../assets/sektionsloggor/omrade.png";
import operan from "../../assets/sektionsloggor/operan.png";
import sakerhet from "../../assets/sektionsloggor/sakerhet.png";
import snaxeriet from "../../assets/sektionsloggor/snaxeriet.png";
import stationen from "../../assets/sektionsloggor/stationen.png";
import vagn from "../../assets/sektionsloggor/vagn.png";
import vieriet from "../../assets/sektionsloggor/vieriet.png";
import denstegrandeenhorningen from "../../assets/sektionsloggor/denstegrandeenhorningen.png";
import krogathen from "../../assets/sektionsloggor/krogathen.png";
import kroggamlakirurgen from "../../assets/sektionsloggor/kroggamlakirurgen.png";
import krogkungshuset from "../../assets/sektionsloggor/krogkungshuset.png";
import lundagard from "../../assets/sektionsloggor/lundagard.png";
import showen from "../../assets/sektionsloggor/showen.png";
import taltnoje from "../../assets/sektionsloggor/taltnoje.png";
import tombolan from "../../assets/sektionsloggor/tombolan.png";
import cirkusen from "../../assets/sektionsloggor/cirkusen.png";
import kabaren from "../../assets/sektionsloggor/kabaren.png";
import foodtrucks from "../../assets/sektionsloggor/foodtrucks.png";
import revyn from "../../assets/sektionsloggor/revyn.png";
import karnebalen from "../../assets/sektionsloggor/karnebalen.png";
import karnevalshelgen from "../../assets/sektionsloggor/karnevalshelgen.png";
import uppropet from "../../assets/sektionsloggor/uppropet2.png";
import efterkarnevalen from "../../assets/sektionsloggor/efterkarnevalen.png";
import fabriken from "../../assets/sektionsloggor/fabriken.png";
import stadveckan from "../../assets/sektionsloggor/stadveckan.png";
import sminkfest from "../../assets/sektionsloggor/sminkfest.png";
import konstifiket from "../../assets/sektionsloggor/konstifiket.png";
import karneveljen from "../../assets/sektionsloggor/karneveljen.png";

export default {
  administerit,
  barnevalen,
  biljonsen,
  bladderiet,
  cirkusen,
  dansen,
  drommerian,
  ekonomi,
  efterkarnevalen,
  fabriken,
  filmen,
  karnebalen,
  karnevalshelgen,
  konstifiket,
  komsek,
  krogarna,
  krogeriet,
  mediahuset,
  musiksektionen,
  nojen,
  omrade,
  operan,
  sakerhet,
  sminkfest,
  snaxeriet,
  stadveckan,
  stationen,
  vagn,
  vieriet,
  denstegrandeenhorningen,
  krogathen,
  kroggamlakirurgen,
  krogkungshuset,
  lundagard,
  showen,
  taltnoje,
  tombolan,
  kabaren,
  foodtrucks,
  revyn,
  uppropet,
  karneveljen
};
