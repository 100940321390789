/* global fetch localStorage */
/* eslint-disable import/prefer-default-export */

const AWS = require("aws-sdk");
const config = require("../../config.json");

// Movies
// ==========================
export function getMovies(cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const token = localStorage.getItem("accessToken");
  if (token) {
    const creds = AWS.config.credentials;
    creds.params.IdentityId = localStorage.getItem("identityid");
    creds.params.Logins = {
      "cognito-identity.amazonaws.com": token
    };
  }
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamMovies",
      Payload: JSON.stringify({ action: "getmovies" })
    },
    cb
  );
}

export function getImages(section, offset, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const token = localStorage.getItem("accessToken");
  if (token) {
    const creds = AWS.config.credentials;
    creds.params.IdentityId = localStorage.getItem("identityid");
    creds.params.Logins = {
      "cognito-identity.amazonaws.com": token
    };
  }
  const input = {
    token,
    section,
    offset
  };

  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamImage",
      Payload: JSON.stringify(input)
    },
    cb
  );
}

export function updateMovie(newMovie, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const input = {
    action: "createmovie",
    movie: newMovie,
    token: localStorage.getItem("accessToken")
  };
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamMovies",
      Payload: JSON.stringify(input)
    },
    cb
  );
}
export function deleteMovie(title, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const input = {
    action: "deletemovie",
    token: localStorage.getItem("accessToken"),
    title
  };
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamMovies",
      Payload: JSON.stringify(input)
    },
    cb
  );
}
export function getSignedURL(file, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const creds = AWS.config.credentials;
  creds.params.IdentityId = localStorage.getItem("identityid");
  creds.params.Logins = {
    "cognito-identity.amazonaws.com": localStorage.getItem("accessToken")
  };

  const input = {
    moviefilename: file,
    token: localStorage.getItem("accessToken")
  };
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamSignedURL",
      Payload: JSON.stringify(input)
    },
    cb
  );
}

// Files
// ==========================
export function getFiles(cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const creds = AWS.config.credentials;
  creds.params.IdentityId = localStorage.getItem("identityid");
  creds.params.Logins = {
    "cognito-identity.amazonaws.com": localStorage.getItem("accessToken")
  };
  const lambda = new AWS.Lambda();
  const input = {
    action: "getfiles",
    token: localStorage.getItem("accessToken")
  };
  lambda.invoke(
    {
      FunctionName: "KarneStreamContent",
      Payload: JSON.stringify(input)
    },
    cb
  );
}
export function uploadFile(name, newFile, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const input = {
    action: "uploadfile",
    body: newFile,
    fileName: name,
    token: localStorage.getItem("accessToken")
  };
  const creds = AWS.config.credentials;
  creds.params.IdentityId = localStorage.getItem("identityid");
  creds.params.Logins = {
    "cognito-identity.amazonaws.com": localStorage.getItem("accessToken")
  };
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamContent",
      Payload: JSON.stringify(input)
    },
    cb
  );
}
export function deleteFile(name, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const input = {
    action: "deletefile",
    token: localStorage.getItem("accessToken"),
    fileName: name
  };
  const creds = AWS.config.credentials;
  creds.params.IdentityId = localStorage.getItem("identityid");
  creds.params.Logins = {
    "cognito-identity.amazonaws.com": localStorage.getItem("accessToken")
  };
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamContent",
      Payload: JSON.stringify(input)
    },
    cb
  );
}

// Users
// ==========================
export function login(user, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamLogin",
      Payload: JSON.stringify(user)
    },
    cb
  );
}
export function registerUser(user, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamCreateUser",
      Payload: JSON.stringify(user)
    },
    cb
  );
}

export function claimAccount(email, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamClaimAccount",
      Payload: JSON.stringify({
        email
      })
    },
    cb
  );
}
export function verifyKarnevalist(email, password, token, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamVerifyKarnevalist",
      Payload: JSON.stringify({
        email,
        password,
        token
      })
    },
    cb
  );
}

export function verifyEmail(email, token, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamVerifyUser",
      Payload: JSON.stringify({
        email,
        verify: token
      })
    },
    cb
  );
}
export function forgotPassword(email, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamLostPassword",
      Payload: JSON.stringify({
        email
      })
    },
    cb
  );
}
export function resetPassword(email, password, token, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });
  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamResetPassword",
      Payload: JSON.stringify({
        email,
        password,
        lost: token
      })
    },
    cb
  );
}
export function verifyPremium(email, code, cb) {
  AWS.config.region = config.REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.IDENTITY_POOL_ID
  });

  const creds = AWS.config.credentials;
  creds.params.IdentityId = localStorage.getItem("identityid");
  creds.params.Logins = {
    "cognito-identity.amazonaws.com": localStorage.getItem("accessToken")
  };

  const lambda = new AWS.Lambda();
  lambda.invoke(
    {
      FunctionName: "KarneStreamCheck",
      Payload: JSON.stringify({
        email,
        vidcode: code
      })
    },
    cb
  );
}

// ==================================================

// export function registerUser (user) {
//   return fetch(`${API_ROOT}/register`, {
//     ...postHeader,
//     body: JSON.stringify(user),
//   })
// }

// export function login (user) {
//   return fetch(`${API_ROOT}/login/email`, {
//     ...postHeader,
//     body: JSON.stringify(user),
//   })
// }
