import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Button from "./Button";
import * as movieActions from "../actions/movieActions";
import MovieImage from "./MovieImage";

function isPremium(userGroup) {
  return userGroup && userGroup.indexOf("premium") > 0;
}

function isKarnevalist(userGroup) {
  return userGroup && userGroup.indexOf("karnevalist") > 0;
}

class Welcome extends Component {
  componentDidMount() {
    this.props.getMovies();
  }

  render() {
    const movies = this.props.movies || [];

    const userGroup = localStorage.getItem("user_group");
    const movieList = movies.map((m, i) => (
      <MovieImage
        movieIndex={i}
        movie={m}
        disabled={!userGroup || userGroup.indexOf(m.group.S) < 0}
        key={m.title.S}
      />
    ));

    return (
      <section>
        <h1 style={{ marginLeft: 0, marginRight: 0 }}>Wehej!</h1>

        {userGroup && !isPremium(userGroup) && !isKarnevalist(userGroup) && (
          <p>
            För att låsa upp filmen och dess utsökta bonusmaterial så måste du
            ange koden som du införskaffade i Drömmerian under karnevalen.
          </p>
        )}
        {!userGroup && (
          <div>
            <p>
              För att komma åt KarnePLAY måste du vara inloggad. Ett inlogg har
              du antingen genom att du köpte en premiumkod i Drömmerian under
              karnevalen, eller för att du är en av alla underbara
              Imaginalkarnevalister som var med och skapade den bästa
              Lundakarnevalen någonsin. Som karnevalist loggar du in med mailen
              du registrerade dig med i appen eller på karnevalist.se inför
              Uppropet.
            </p>
            <p>Vill du skapa ett konto kan du göra det här:</p>
            <Link to="/register">
              <Button
                title="Registrera ett konto"
                small
                className="login-button"
                type="tertiary"
              />
            </Link>
          </div>
        )}
        <p>
          Har du några problem med att logga in kontakta oss på
          <a href="mailto:it@lundakarnevalen.se">it@lundakarnevalen.se</a>
          så ska vi försöka hjälpa dig.
        </p>

        <section />
        <section>{isPremium(userGroup) && movieList}</section>
      </section>
    );
  }
}

Welcome.propTypes = {
  getMovies: PropTypes.func.isRequired,
  movies: PropTypes.array
};

Welcome.defaultProps = {
  movies: []
};

function mapStateToProps(state) {
  return {
    accessToken: state.user.accessToken,
    movies: state.movie.movies
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...movieActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Welcome);
