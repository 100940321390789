import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MovieImage from "../../components/MovieImage";
import * as movieActions from "../../actions/movieActions";

import "../../styles/Karnevalist.css";

class MovieGrid extends React.Component {
  componentDidMount() {
    this.props.getMovies();
  }
  render() {
    const { movies, year, header } = this.props;
    if (!movies || !movies.filter) {
      return null;
    }
    console.log("hej");
    const movieList = movies
      .filter(item => parseInt(item.year.N, 10) === year)
      .sort((a, b) => {
        if (a.title.S > b.title.S) {
          return 1;
        }
        if (a.title.S < b.title.S) {
          return -1;
        }
        return 0;
      })
      .map((m, i) => (
        <MovieImage
          movieIndex={i}
          movie={m}
          disabled={false}
          key={m.title.S}
        />
      ));
    return (
      <div className="row">
        <h1 className="head">{header}</h1>
        <div className="karneWrapper">
          <section className="movieWrapper">{movieList}</section>
        </div>
      </div>
    );
  }
}
MovieGrid.propTypes = {
  getMovies: PropTypes.func.isRequired,
  movies: PropTypes.arrayOf(PropTypes.object),
  year: PropTypes.number,
  header: PropTypes.string
};
MovieGrid.defaultProps = {
  movies: [],
  header: "Movie grid",
  year: 0
};

function mapStateToProps(state) {
  return {
    movies: state.movie.movies
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...movieActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(MovieGrid);

