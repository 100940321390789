import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TextField from "material-ui/TextField";
import CircularProgress from "material-ui/CircularProgress";
import Snackbar from "material-ui/Snackbar";
import PropTypes from "prop-types";

import Button from "../components/Button";
import { validPassword } from "../utils/validation";
import * as userActions from "../actions/userActions";
import "../styles/Karnevalist.css";


class KarnevalistVerify extends React.Component {
  state = {
    password: "",
    passwordRepeat: "",
    passwordErrorText: "",
    passwordRepeatErrorText: "",
    gdpr: false
  };

  validateAndSend = () => {
    const { password, passwordRepeat } = this.state;
    let error = false;
    const errors = {
      emailErrorText: "",
      passwordErrorText: "",
      passwordRepeatErrorText: ""
    };

    if (!validPassword(password)) {
      error = true;
      errors.passwordErrorText =
        "Ogiltigt lösenord. Ett lösenord måste innehålla minst 6 tecken.";
    }

    if (password !== passwordRepeat) {
      error = true;
      errors.passwordRepeatErrorText = "Både lösenords-fälten måste matcha.";
    }

    this.setState(errors);

    if (!this.state.gdpr) {
      alert("Du måste godkänna villkoren.");
      return;
    }


    if (!error) {
      this.sendReset();
    }
  };
  sendReset = async() => {
    const { token } = this.props;
    const email = decodeURIComponent(this.props.email);
    const { password } = this.state;
    this.props.verifyKarnevalist(email, password, token);
  };

  updateGdpr = (ev) => {
    console.log("Ev", ev.target);
    this.setState({ gdpr: !this.state.gdpr });
  }

  render() {
    if (this.props.resetSuccess) {
      window.location.replace(`${window.location.origin}/login`);
    }
    return (
      <div className="password-reset-wrapper">
        <h1 style={{ margin: 0 }}>Hej Karnevalist-finis!</h1>
        <p style={{ margin: 0 }}>Välj ett lösenord till ditt KarnePLAY konto.</p>
        <TextField
          label="Epost"
          floatingLabelText={decodeURIComponent(this.props.email)}
          type="text"
          disabled
          fullWidth
        />
        <TextField
          onChange={(evt, val) => this.setState({ password: val })}
          onFocus={() =>
            this.setState({ passwordErrorText: "" })
          }
          errorText={this.state.passwordErrorText}
          floatingLabelText="Lösenord"
          type="password"
          fullWidth
        />
        <TextField
          onChange={(evt, val) => this.setState({ passwordRepeat: val })}
          onFocus={() =>
            this.setState({ passwordRepeatErrorText: "" })
          }
          errorText={this.state.passwordRepeatErrorText}
          floatingLabelText="Bekräfta lösenord"
          type="password"
          fullWidth
        />
        <p>
          <input
            name="gdpr"
            type="checkbox"
            checked={this.state.isGoing}
            onChange={this.updateGdpr}
            style={{ padding: "15px", marginRight: "15px" }}
          />Jag godkänner att Lundakarnevalen hanterar mina uppgifter angivna här på KarnePLAY
        </p>
        {this.props.loading ? (
          <CircularProgress size={40} />
        ) : (
          <Button
            style={{ marginLeft: 0, marginTop: 40, width: "100%" }}
            medium
            rounded
            type="tertiary"
            title="Klar"
            onClick={this.validateAndSend}
          />
        )}
        <Snackbar
          message={this.props.message || ""}
          open={!!this.props.message}
          autoHideDuration={4000}
        />
      </div>
    );
  }
}

KarnevalistVerify.propTypes = {
  loading: PropTypes.bool,
  resetSuccess: PropTypes.bool,
  verifyKarnevalist: PropTypes.func.isRequired,
  message: PropTypes.string,
  email: PropTypes.string,
  token: PropTypes.string
};

KarnevalistVerify.defaultProps = {
  loading: false,
  resetSuccess: false,
  message: "",
  email: "",
  token: ""
};

function mapStateToProps(state) {
  return {
    error: state.user.error,
    resetSuccess: state.user.resetSuccess,
    loading: state.user.loading,
    message: state.user.message
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    verifyKarnevalist: userActions.verifyKarnevalist
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(KarnevalistVerify);
