import React from "react";
import Placeholder from "../../assets/dawn.jpg";
import "../../styles/Karnevalist.css";

const Header = () => (
  <div className="herobanner">
    <img src={Placeholder} alt="banner" />
  </div>
);

export default (Header);
