import * as types from "../constants";

export function clearError() {
  return {
    type: types.CLEAR_ERROR
  };
}

export function clearMessage() {
  return {
    type: types.CLEAR_MESSAGE
  };
}

export function clearFoundUser() {
  return {
    type: types.CLEAR_FOUND_USER
  };
}
