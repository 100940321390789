export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const GET_SIGNED_URL = "GET_SIGNED_URL";
export const GET_SIGNED_URL_FAIL = "GET_SIGNED_URL_FAIL";
export const GET_SIGNED_URL_SUCCESS = "GET_SIGNED_URL_SUCCESS";
export const MOVIE_PLAY = "MOVIE_PLAY";
export const SHOW_MOVIE_DETAIL = "SHOW_MOVIE_DETAIL";
export const HIDE_MOVIE_DETAIL = "HIDE_MOVIE_DETAIL";
export const GET_MOVIES = "GET_MOVIES";
export const GET_MOVIES_SUCCESS = "GET_MOVIES_SUCCESS";
export const GET_MOVIES_FAIL = "GET_MOVIES_FAIL";
export const UPDATE_MOVIE = "UPDATE_MOVIE";
export const UPDATE_MOVIE_SUCCESS = "UPDATE_MOVIE_SUCCESS";
export const UPDATE_MOVIE_FAIL = "UPDATE_MOVIE_FAIL";
export const DELETE_MOVIE = "DELETE_MOVIE";
export const DELETE_MOVIE_SUCCESS = "DELETE_MOVIE_SUCCESS";
export const DELETE_MOVIE_FAIL = "DELETE_MOVIE_FAIL";

export const GET_FILES = "GET_FILES";
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
export const GET_FILES_FAIL = "GET_FILES_FAIL";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";
export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAIL = "DELETE_FILE_FAIL";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const SET_USER = "SET_USER";
export const SET_USER_SUCCESS = "SET_USER_SUCCESS";
export const SET_USER_FAIL = "SET_USER_FAIL";

export const GET_MOVIESLIST = "GET_MOVIESLIST";
export const GET_MOVIESLIST_SUCCESS = "GET_MOVIESLIST_SUCCESS";
export const GET_MOVIESLIST_FAIL = "GET_MOVIESLIST_FAIL";

export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const VERIFY_KARNEVALIST_FAIL = "VERIFY_KARNEVALIST_FAIL";

export const VERIFY_PREMIUM = "VERIFY_PREMIUM";
export const VERIFY_PREMIUM_SUCCESS = "VERIFY_PREMIUM_SUCCESS";
export const VERIFY_PREMIUM_FAIL = "VERIFY_PREMIUM_FAIL";

export const REHYDRATE_TOKEN = "REHYDRATE_TOKEN";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const CLEAR_FOUND_USER = "CLEAR_FOUND_USER";

export const GET_IMAGES = "GET_IMAGES";
export const GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS";
export const GET_IMAGES_FAIL = "GET_IMAGES_FAIL";
export const CLEAN_IMAGES = "CLEAN_IMAGES";
