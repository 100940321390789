import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="wrapper" style={{ textAlign: "left" }}>
        <h1 style={{ color: "#8a4797", fontSize: 60 }}>Om KarnePLAY</h1>

        <p>
          KarnePLAY finns idag för att streama Karnevalsfilmen till de som köpte
          premiumkoder under Karnevalshelgen. Utöver detta har vi även gett alla
          karnevalister speciell tillgång till karnevalsmaterial. Det är en egen
          netflix, bara för karnevalen!
        </p>
        <p>
          <em>
            Allt gott har sitt slut, och tyvärr gäller detta även KarnePLAY.
            Karnevalens verksamhet tar uppehåll under mellanåren fram tills dess
            att nästa karnevalsgäng är vald. Därmed garanterar vi inte att
            KarnePLAY underhålls längre än till sommaren 2019 (OBS, detta har
            förlängts då karneplay även kommer användas internt under våren
            2019). Det finns möjlighet att ladda ner filmen lokalt.
          </em>
        </p>
        <h3 style={{ color: "rgb(138, 71, 151)" }}> Ladda ner </h3>
        <p>
          Då Lundakarnevalen är en ideell studentförening kan vi inte garantera
          att hemsidan underhålls tills nästa Karneval. Därför har du chansen
          att ladda ner filmerna ifall du vill kunna se dem för alltid. Detta
          gör du genom att spela upp en film och i menyn klicka på "Ladda ner".
        </p>
        <h3 style={{ color: "rgb(138, 71, 151)" }}> Vid fel </h3>
        <p>
          Då det är studenter som byggt denna sida kan det finnas en och annan
          bugg som gör att hemsidan uppför sig busigt.
        </p>
        <p>
          <strong style={{ color: "rgb(138, 71, 151)" }}>
            Om du har problem med registrering eller inlogg
          </strong>
          : Testa gärna att byta webbläsare till en ny version av Chrome eller
          Firefox. Om det inte går eller fungerar så maila IT med beskrivning av
          vilken webbläsare du använder och vilket operativ system
          (windows/linux/mac).
        </p>
        <p>
          <strong style={{ color: "rgb(138, 71, 151)" }}>
            Om du har problem med premiumkoden
          </strong>
          : Vårt typsnitt visar tyvärr bokstaven I och siffran 1 rätt lika.
          Samma gäller ävenak för bokstaven O och siffran 0. Dessa förväxlas
          lätt, så testa gärna olika kombinationer. Om detta inte löser ditt
          problem så maila IT så hjälper vi mer än gärna till.
        </p>
        <h3 style={{ color: "rgb(138, 71, 151)" }}> Kontakt </h3>
        <p>
          {" "}
          Vid frågor om KarnePLAY mailar du: <br />
          <a
            style={{ color: "rgb(138, 71, 151)" }}
            href="mailto:it@lundakarnevalen.se"
          >
            it@lundakarnevalen.se
          </a>
        </p>
      </div>
    );
  }
}
export default About;
