/* global localStorage */

import * as types from "../constants";

const initialState = {
  images: {},
  images_loading: false,
  images_error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_IMAGES: {
      return {
        ...state,
        images_loading: true,
        upsert_success: false,
        delete_success: false,
        images_error: null,
        loading: true
      };
    }
    case types.GET_IMAGES_SUCCESS: {
      if (!state.images_loading) {
        return state;
      }
      const { images, zip } = action.payload;
      const oldImgs = (state.images && state.images.images) || [];
      return {
        ...state,
        images_loading: false,
        loading: false,
        images: {
          images: oldImgs.concat(images),
          zip
        },
        images_error: null
      };
    }
    case types.GET_IMAGES_FAIL: {
      return {
        ...state,
        images_loading: false,
        images: undefined,
        loading: false,
        images_error: "Failed to retrieve images"
      };
    }
    case types.CLEAN_IMAGES: {
      return {
        ...state,
        images_loading: false,
        images: undefined,
        loading: false
      };
    }

    default:
      return state;
  }
}
