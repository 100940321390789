/* global localStorage */
import * as types from "../constants";
import * as API from "./api";

/* eslint-disable import/prefer-default-export */
export function getImages(section, offset) {
  return async dispatch => {
    dispatch({
      type: types.GET_IMAGES
    });
    try {
      API.getImages(section, offset, (err, res) => {
        if (err || res.StatusCode !== 200) {
          dispatch({
            type: types.GET_IMAGES_FAIL,
            payload: {
              err
            }
          });
        } else {
          sessionStorage.setItem("images", res.Payload);
          dispatch({
            type: types.GET_IMAGES_SUCCESS,
            payload: JSON.parse(res.Payload)
          });
        }
      });
    } catch (err) {
      dispatch({
        type: types.GET_IMAGES_FAIL,
        payload: { error: "Server error." }
      });
    }
  };
}
export function cleanImages() {
  return async dispatch => {
    dispatch({
      type: types.CLEAN_IMAGES
    });
  };
}
