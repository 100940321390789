/* global localStorage */

import * as types from "../constants";

const initialState = {
  movies: [],
  movies_loading: false,
  movies_error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.MOVIE_PLAY: {
      return {
        ...state,
        play: action.payload,
        moviedetail: null
      };
    }
    case types.SHOW_MOVIE_DETAIL: {
      return {
        ...state,
        moviedetail: action.payload
      };
    }
    case types.HIDE_MOVIE_DETAIL: {
      return {
        ...state,
        moviedetail: null
      };
    }
    case types.GET_MOVIES: {
      return {
        ...state,
        movies_loading: true,
        upsert_success: false,
        delete_success: false,
        movies_error: null
      };
    }
    case types.GET_MOVIES_SUCCESS: {
      return {
        ...state,
        movies_loading: false,
        movies: action.payload,
        movies_error: null
      };
    }
    case types.GET_MOVIES_FAIL: {
      return {
        ...state,
        movies_loading: false,
        movies: null,
        movies_error: "Failed to retrieve movies"
      };
    }

    case types.UPDATE_MOVIE: {
      return {
        ...state,
        movies_loading: true,
        upsert_success: false,
        movies_error: null
      };
    }
    case types.UPDATE_MOVIE_SUCCESS: {
      return {
        ...state,
        movies_loading: false,
        upsert_success: true,
        movies_error: null
      };
    }
    case types.UPDATE_MOVIE_FAIL: {
      return {
        ...state,
        movies_loading: false,
        upsert_success: false,
        movies_error: "Failed to update movie"
      };
    }

    case types.DELETE_MOVIE: {
      return {
        ...state,
        movies_loading: true,
        delete_success: false,
        movies_error: null
      };
    }
    case types.DELETE_MOVIE_SUCCESS: {
      return {
        ...state,
        movies_loading: false,
        delete_success: true,
        movies_error: null
      };
    }
    case types.DELETE_MOVIE_FAIL: {
      return {
        ...state,
        movies_loading: false,
        delete_success: false,
        movies_error: "Failed to update movie"
      };
    }

    case types.GET_SIGNED_URL: {
      return {
        ...state,
        url_loading: true,
        url_err_message: null,
        url_err_payload: null
      };
    }
    case types.GET_SIGNED_URL_SUCCESS: {
      return {
        ...state,
        url_loading: false,
        signedurl: action.payload,
        url_err_message: null,
        url_err_payload: null
      };
    }
    case types.GET_SIGNED_URL_FAIL: {
      return {
        ...state,
        url_loading: false,
        url_err_message: JSON.stringify(action.payload),
        url_err_payload: JSON.stringify(action.payload)
      };
    }

    default:
      return state;
  }
}
