import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Snackbar from "material-ui/Snackbar";
import CircularProgress from "material-ui/CircularProgress";
import TextField from "material-ui/TextField";
import Button from "../components/Button";

import * as validation from "../utils/validation";
import * as userActions from "../actions/userActions";
import * as uiActions from "../actions/uiActions";

import "../styles/Login.css";

class Login extends Component {
  state = {
    checked: false,
    email: "",
    password: "",
    passwordValidation: {
      error: false,
      errorText: "Lösenord obligatorisk"
    },
    emailValidation: {
      error: false,
      errorText: "Ogiltigt epost"
    }
  };

  updateCheck = () => this.setState({ checked: !this.state.checked });
  updateEmail = (evt, val) => this.setState({ email: val });
  updatePassword = (evt, val) => this.setState({ password: val });
  updatePasswordValidation = val =>
    this.setState({
      passwordValidation: { ...this.state.passwordValidation, error: val }
    });
  updateEmailValidation = val =>
    this.setState({
      emailValidation: { ...this.state.emailValidation, error: val }
    });

  updatePasswordError = () =>
    this.updatePasswordValidation(this.state.password === "");
  updateEmailError = () =>
    this.updateEmailValidation(!validation.validEmail(this.state.email));

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.login();
    }
  };

  login = () => {
    this.updateEmailError();
    this.updatePasswordError();
    if (
      !this.state.emailValidation.error &&
      !this.state.passwordValidation.error &&
      this.state.password !== "" &&
      validation.validEmail(this.state.email)
    ) {
      this.props.login({
        email: this.state.email,
        password: this.state.password
      });
    }
  };

  componentDidMount() {}
  render() {
    return (
      <div className="wrapper">
        <h1 style={{ color: "#8a4797", fontSize: 60 }}>Logga in</h1>
        <TextField
          fullWidth
          floatingLabelText="Epost"
          hintText="Epost"
          onBlur={this.updateEmailError}
          onKeyPress={this.handleKeyPress}
          onChange={this.updateEmail}
          errorText={
            this.state.emailValidation.error
              ? this.state.emailValidation.errorText
              : ""
          }
        />
        <TextField
          fullWidth
          floatingLabelText="Lösenord"
          hintText="Lösenord"
          type="password"
          onBlur={this.updatePasswordError}
          onChange={this.updatePassword}
          onKeyPress={this.handleKeyPress}
          errorText={
            this.state.passwordValidation.error
              ? this.state.passwordValidation.errorText
              : ""
          }
        />
        <Button
          title="Logga in"
          className="login-button"
          type={this.props.loading ? "disabled" : "tertiary"}
          onClick={() => this.login()}
        />
        <Link
          to="/forgotpassword"
          style={{ fontSize: "12px", display: "block" }}
        >
          Glömt lösenord?
        </Link>
        {this.props.loading && <CircularProgress style={{ marginTop: 20 }} />}
        {this.props.resetSuccess && (
          <Snackbar
            open={this.props.resetSuccess}
            message={this.props.message}
            autoHideDuration={4000}
          />
        )}
        <Snackbar
          open={!!this.props.error}
          message={this.props.error || ""}
          autoHideDuration={4000}
          onRequestClose={this.props.clearError}
        />
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired
};

Login.defaultProps = {
  error: ""
};

function mapStateToProps(state) {
  return {
    accessToken: state.user.accessToken,
    resetSuccess: state.user.resetSuccess,
    error: state.user.error,
    loading: state.user.loading,
    message: state.user.message
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions, ...uiActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
