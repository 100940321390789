import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Snackbar from "material-ui/Snackbar";
import Button from "../components/Button";
import CircularProgress from "material-ui/CircularProgress";

import "../styles/Register.css";
import TextField from "material-ui/TextField";
import * as validation from "../utils/validation";
import * as userActions from "../actions/userActions";
import * as uiActions from "../actions/uiActions";

class Register extends Component {
  state = {
    checked: false,
    email: "",
    password: "",
    passwordValidation: {
      error: false,
      errorText: "Password required"
    },
    verifyPasswordValidation: {
      error: false,
      errorText: "Password required"
    },
    emailValidation: {
      error: false,
      errorText: "Invalid email"
    }
  };

  constructor(props) {
    super(props);
    console.log("aeou");
    this.myRef = React.createRef();
    setTimeout(window.scrollTo(0, this.myRef.offsetTop), 100);
  }

  updateCheck = () => this.setState({ checked: !this.state.checked });
  updateEmail = (evt, val) => this.setState({ email: val });
  updatePassword = (evt, val) => this.setState({ password: val });
  updateVerifyPassword = (evt, val) => this.setState({ verifypassword: val });
  updatePasswordValidation = val =>
    this.setState({
      passwordValidation: { ...this.state.passwordValidation, error: val }
    });
  updateEmailValidation = val =>
    this.setState({
      emailValidation: { ...this.state.emailValidation, error: val }
    });

  updatePasswordError = () =>
    this.updatePasswordValidation(this.state.password === "");
  updateEmailError = () =>
    this.updateEmailValidation(!validation.validEmail(this.state.email));

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.login();
    }
  };

  register() {
    this.updateEmailError();
    this.updatePasswordError();
    if (
      !this.state.emailValidation.error &&
      !this.state.passwordValidation.error &&
      this.state.password !== "" &&
      validation.validEmail(this.state.email) &&
      this.state.password === this.state.verifypassword
    ) {
      this.props.register({
        email: this.state.email,
        password: this.state.password
      });
    }
    if (this.state.password !== this.state.verifypassword) {
      alert("Dina lösenord matchade inte med varandra");
    }
  }

  render() {
    const registered_info = this.props.registered ? (
      <p>
        Din registrering lyckades, du kommer nu att få ett epost för att
        verifiera din epost.
      </p>
    ) : null;

    return (
      <div className="wrapper">
        <h1 style={{ color: "#8a4797", fontSize: 60 }}>Registrering</h1>
        <TextField
          fullWidth
          floatingLabelText="Epost"
          hintText="Epost"
          onBlur={this.updateEmailError}
          onKeyPress={this.handleKeyPress}
          onChange={this.updateEmail}
          errorText={
            this.state.emailValidation.error
              ? this.state.emailValidation.errorText
              : ""
          }
        />
        <TextField
          fullWidth
          floatingLabelText="Lösenord"
          hintText="Lösenord"
          type="password"
          onBlur={this.updatePasswordError}
          onChange={this.updatePassword}
          onKeyPress={this.handleKeyPress}
          errorText={
            this.state.passwordValidation.error
              ? this.state.passwordValidation.errorText
              : ""
          }
        />
        <TextField
          fullWidth
          floatingLabelText="Verifiera lösenord"
          hintText="Lösenord"
          type="password"
          onBlur={this.updateVerifyPasswordError}
          onChange={this.updateVerifyPassword}
          onKeyPress={this.handleKeyPress}
          errorText={
            this.state.verifyPasswordValidation.error
              ? this.state.verifyPasswordValidation.errorText
              : ""
          }
        />
        <Button
          title="Registrera dig"
          className="login-button"
          type={this.props.loading ? "disabled" : "tertiary"}
          onClick={() => this.register()}
        />
        {this.props.loading && <CircularProgress style={{ marginTop: 20 }} />}
        {registered_info}
        <Snackbar
          open={!!this.props.error}
          message={this.props.error || ""}
          autoHideDuration={4000}
          onRequestClose={this.props.clearError}
        />
      </div>
    );
  }
}

Register.propTypes = {
  register: PropTypes.func.isRequired,
  registered: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  clearError: PropTypes.func.isRequired,
  error: PropTypes.any
};

Register.defaultProps = {
  error: ""
};

function mapStateToProps(state) {
  return {
    loading: state.user.loading,
    error: state.user.error,
    registerErrors: state.user.registerErrors,
    registered: state.user.registered
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions, ...uiActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
