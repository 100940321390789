import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Snackbar from "material-ui/Snackbar";
import CircularProgress from "material-ui/CircularProgress";
import Done from "material-ui/svg-icons/action/done";
import JSONInput from "react-json-editor-ajrm";

import Button from "../components/Button";
import * as movieActions from "../actions/movieActions";

// import '../styles/AdminMovie.css'

const darkTheme = {
  default: "#D4D4D4",
  background: "#1E1E1E",
  background_warning: "#1E1E1E",
  string: "#CE8453",
  number: "#B5CE9F",
  colon: "#49B8F7",
  keys: "#9CDCFE",
  keys_whiteSpace: "#AF74A5",
  primitive: "#6392C6"
};

class AdminMovie extends Component {
  componentWillMount() {
    // Get movies with forceSync.
    // AKA dont load from sessionStorage.
    this.props.getMovies(true);
  }
  componentWillReceiveProps(props) {
    if (props.upsert_success || props.delete_success) {
      this.props.getMovies(true);
    }
  }

  createMovie() {
    const content = this.state.newMovie;

    if (content && content !== "PARSE_ERROR") {
      this.props.updateMovie(content);
    } else {
      alert("Parse JSON error. Please fix.");
    }
  }

  updateMovie(idx) {
    const title = this.props.movies[idx].title.S;
    const content = !!this.state && this.state[title];
    const contentObject = JSON.parse(content);

    if (!content) {
      alert("You have not changed anything on the movie");
    } else if (!contentObject.title || contentObject.title.S !== title) {
      alert("You cant change/delete the title. It is a primary key.");
    } else if (content !== "PARSE_ERROR") {
      this.props.updateMovie(content);
    } else {
      alert("Parse JSON error. Please fix.");
    }
  }

  deleteMovie(idx) {
    const title = JSON.stringify(this.props.movies[idx].title);
    const r = window.confirm("Sure you want to delete this movie?");
    if (r) {
      this.props.deleteMovie(title);
    }
  }

  render() {
    // console.log(3, this.props.movies)
    return (
      <div className="left wrapper">
        <h1 style={{ color: "#8a4797", fontSize: 60 }}>Manage Movies</h1>
        <Link to="/admin/files">
          <Button title="Manage files" type="tertiary" />
        </Link>
        <p>
          Datastrukturen ska vara så som en item i DynamoDb. Alla attribut ska
          object med en key-value, där key är datatyp och value är värdet i
          string. Exempel:
        </p>
        <pre>
          {JSON.stringify({
            title: {
              S: "revyn 2014"
            },
            year: {
              N: "2014"
            },
            relatedMovie: {
              L: [{ S: "bloopers" }, { S: "trailer" }]
            }
          })}
        </pre>
        <p>
          Error-meddelande strular. Om man klickat på upsert och fått error så
          kommer den att visas varje gång man modifierar en kod. Men spelar
          ingen roll då, utan bara när man klickar på upsert.
        </p>

        {this.props.loading && <CircularProgress style={{ marginTop: 20 }} />}
        {this.props.upsert_success && <Done style={{ marginTop: 20 }} />}
        <Snackbar
          open={!!this.props.error}
          message={this.props.error || ""}
          autoHideDuration={4000}
          onRequestClose={this.props.clearError}
        />
        {this.props.movies &&
          Array.isArray(this.props.movies) &&
          this.props.movies.map((m, idx) => (
            <div key={`movieedit-${idx}`}>
              <h3>
                {m.title.S}
                <Button title="Upsert" onClick={() => this.updateMovie(idx)} />
                <Button
                  title="Delete"
                  type="secondary"
                  onClick={() => this.deleteMovie(idx)}
                />
              </h3>
              <JSONInput
                id={`uniqueString-jsonInut-${idx}`}
                style={{ textAlign: "left" }}
                onChange={content => {
                  const newState = {};
                  if (content.error) {
                    newState[m.title.S] = "PARSE_ERROR";
                  } else {
                    newState[m.title.S] = content.json;
                  }
                  this.setState(newState);
                }}
                placeholder={m}
                colors={darkTheme}
                height="250px"
              />
            </div>
          ))}
        <div>
          <h3>
            Ny film
            <Button title="Lägg till" onClick={() => this.createMovie()} />
          </h3>
          <JSONInput
            id="uniqueString-jsonInut-New-Movie"
            style={{ textAlign: "left" }}
            placeholder={(this.state && this.state.newMovie) || ""}
            onChange={content => {
              if (content.error) {
                this.setState({ newMovie: "PARSE_ERROR" });
              } else {
                this.setState({ newMovie: content.json });
              }
            }}
            colors={darkTheme}
            height="250px"
          />
        </div>
      </div>
    );
  }
}

AdminMovie.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool
};

AdminMovie.defaultProps = {
  error: "",
  loading: false
};

function mapStateToProps(state) {
  return {
    movies: state.movie.movies,
    error: state.movie.movies_error,
    loading: state.movie.movies_loading,
    upsert_success: state.movie.upsert_success,
    delete_success: state.movie.delete_success
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...movieActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminMovie);
