import React from "react";
import PropTypes from "prop-types";

const STYLE = {
  textAlign: "center"
};

function AboutMovie({ title, description }) {
  return (
    <div style={STYLE}>
      <h2>{title}</h2>
      <p style={{ maxWidth: "485px", width: "80%", margin: "auto" }}>
        {description}
      </p>
    </div>
  );
}

AboutMovie.defaultProps = {};

AboutMovie.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default AboutMovie;
