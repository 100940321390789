import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import user from "./user";
import movie from "./movie";
import file from "./file";
import image from "./image";

export default combineReducers({
  user,
  file,
  movie,
  image,
  form: formReducer
});
