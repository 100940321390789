import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import { unregister, registerServiceWorker } from "./registerServiceWorker";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { Provider } from "react-redux";
import store from "./store";

const muiTheme = getMuiTheme({
  fontFamily: "Lato, sans-serif",
  palette: {
    textColor: "#333",
    primary1Color: "#8A4797",
    primary2Color: "#FFBBCC"
  },
  appBar: {
    height: 100
  }
});

const Wrapper = () => (
  <Provider store={store}>
    <MuiThemeProvider muiTheme={muiTheme}>
      <App />
    </MuiThemeProvider>
  </Provider>
);

ReactDOM.render(<Wrapper />, document.getElementById("root"));
unregister();
// registerServiceWorker();
