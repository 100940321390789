import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CircularProgress from "material-ui/CircularProgress";
import DownloadIcon from "material-ui/svg-icons/file/cloud-download";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import NavigationArrowForward from "material-ui/svg-icons/navigation/arrow-forward";
import NavigationArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import IconButton from "material-ui/IconButton";

import Button from "../components/Button";
import Header from "./Karnevalist/Header";
import sections from "../sections.json";

import "../styles/ImageGallery.css";
import * as imageActions from "../actions/imageActions";

class ImageGallery extends Component {
  constructor(props) {
    super(props);
    const section = decodeURIComponent(props.match.params.section);
    this.state = {
      currentImage: -1,
      sections: Object.keys(sections).reduce(
        (acc, curr) => (section === sections[curr] ? curr : acc),
        ""
      )
    };

    this.props.getImages(this.state.sections, 0);

    this.keylistener = this.keylistener.bind(this);
    document.addEventListener("keydown", this.keylistener, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keylistener, false);
    this.props.cleanImages();
  }

  keylistener(event) {
    if (event.keyCode === 27) {
      // escape
      this.updateCurrImg(-1);
    } else if (event.keyCode === 37) {
      // left
      this.prev();
    } else if (event.keyCode === 39) {
      // right
      this.next();
    }
  }
  prev = () => this.updateCurrImg(this.state.currentImage - 1);
  next = () => this.updateCurrImg(this.state.currentImage + 1);

  updateCurrImg = idx => {
    const imgs = this.props.images && this.props.images.images;
    if (!imgs || imgs.length === 0) {
      return this.setState({ currentImage: -1 });
    }
    if (idx === imgs.length) {
      return;
    }
    if (imgs.length - idx <= 3 && !this.props.loading) {
      this.loadMore();
    }

    this.setState({ currentImage: idx });
  };

  loadMore = () => {
    const section = this.props.match.params.section;
    const imgs = (this.props.images && this.props.images.images) || [];
    const offset = imgs.length;
    this.props.getImages(this.state.sections, offset);
  };

  closeLightbox = () => this.updateCurrImg(-1);

  render() {
    const { images } = this.props;
    const loading = (
      <div>
        <CircularProgress style={{ marginTop: "50px" }} />
        <p>Loading...</p>
      </div>
    );

    const imgs = (images && images.images) || [];
    const imgHtml = imgs.map((i, idx) => (
      <div key={i.name} className="gridimg">
        <img
          alt={`karneimg ${idx}`}
          src={i.src}
          onClick={() => this.updateCurrImg(idx)}
        />
        <div className="imgShadow" />
        <span>{i.name}</span>
      </div>
    ));
    const lightbox =
      this.state.currentImage > -1 ? (
        <div className="lightbox">
          <div className="shadow" onClick={this.closeLightbox} />
          <span>{imgs[this.state.currentImage].name}</span>
          <img
            alt="Current"
            src={imgs[this.state.currentImage].src}
            className="lightbox-image"
          />
          <NavigationClose
            className="clear"
            color="white"
            onClick={this.closeLightbox}
          />
          <div className="leftCont" onClick={this.prev}>
            <NavigationArrowBack className="left" color="white" />
          </div>
          <div className="rightCont" onClick={this.next}>
            <NavigationArrowForward className="right" color="white" />
          </div>
        </div>
      ) : null;

    return (
      <div className="image-gallery-wrapper">
        <Header />
        <h1>{this.props.match.params.section}</h1>
        <div className="download-wrapper">
          {images && images.zip && (
            <div className="download-icon">
              <a href={images.zip.url}>
                <IconButton
                  iconStyle={{
                    width: 40,
                    height: 40
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </a>
            </div>
          )}
          <div className="download-text">
            <p>
              Klicka på det lilla molnet till höger för att ladda ner alla
              bilder till din egen dator!
            </p>
            <p>
              <strong>Rättigheter</strong>
              <br />
              Bilder som laddas ner från KarnePLAY får enbart användas för
              privat bruk. Bilderna får inte utan tillåtelse från
              Lundakarnevalen t ex tryckas i en bok eller tidning, publiceras på
              en hemsida eller framföras i andra officiella sammanhang. I
              enlighet med upphovsrätten ska fotografen namnges vid framförandet
              av bilden.
              <br />
              <br />
              <strong>Spridning</strong>
              <br />
              Alla karnevalister har tillgång till bilderna genom inloggning på
              KarnePLAY. Det är förbjudet att sprida bilderna vidare.
              <br />
              <br />
              <strong>Profilbilder</strong>
              <br />
              Vill du använda en bild som profilbild någonstans är det OK om du
              tillfrågar fotografen vars namn eller initialer oftast står i
              bildens namn. Försök i första hand hitta och kontakta fotografen
              själv. Hör av dig till info@lundakarnevalen.se eller
              Lundakarnevalens Facebooksida vid frågor eller problem (notera att
              svarsfrekvensen kan vara låg i mellanåren).
            </p>
            <p>
              Bilderna är huvudsakligen tagna av våra karnevalsfotografer Max af
              Klercker, Axel Schiller, Emma Klang, Jesper Schwarz, Deon Lim,
              Maja Björsne, Christina Abdulahad, Philip Ohlin, Isaac
              Cedercrantz, Annika Jönsson, Ashraf Khoshiwal, Peter Nguyen,
              Rebecka Boman, Crasy Wolfang, Elin Vilhelmsson, Alexander
              Edthofter och Elis Ilumets. Andra som bidragit med flertalet
              bilder är Per Lindström, Jonas Jacobsson, Kristoffer Lindblad,
              Axel Wiktor (Barnevalen), Johanna Molin (Operan) och Louise La-Ek
              (Operan).
            </p>
          </div>
        </div>
        {!imgs.length && loading}
        {imgHtml}
        {lightbox}
        <br />
        <Button
          title="Ladda fler..."
          className="login-button"
          type={this.props.loading ? "disabled" : "tertiary"}
          onClick={() => this.loadMore()}
        />
      </div>
    );
  }
}

ImageGallery.propTypes = {
  getImages: PropTypes.func.isRequired,
  images: PropTypes.object.isRequired,
  images_loading: PropTypes.bool,
  loading: PropTypes.bool
};

ImageGallery.defaultProps = {
  images_loading: false,
  loading: false
};

function mapStateToProps(state) {
  return {
    images: state.image.images,
    images_loading: state.image.images_loading,
    loading: state.image.loading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...imageActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageGallery);
