import React, { Component } from "react";
import PropTypes from "prop-types";
import "../styles/MovieImage.css";
import phimage from "../assets/placeholder.jpg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as movieActions from "../actions/movieActions";

class MovieImage extends Component {
  showMovieDetail = () => {
    this.props.showMovieDetail(this.props.movie);
  };
  render() {
    const m = this.props.movie;

    // While coding offline...
    // const posterfile = phimage
    const posterfile = m.posterfile.S || phimage;
    const link = m.styleconfig
      ? `/moviedetail/${this.props.movieIndex}`
      : `/play/${encodeURI(m.title.S)}`;

    const disabled = this.props.disabled;
    if (!disabled) {
      return (
        <Link to={link}>
          <div className="movie-image">
            <img src={posterfile} alt={m.title.S} />
            <div className="shadow" />
            <span>{m.title.S}</span>
          </div>
        </Link>
      );
    }
    return (
      <div className="movie-image">
        <img src={posterfile} alt={m.title.S} />
        <div className="shadow" />
        <span>{m.title.S}</span>
      </div>
    );
  }
}

MovieImage.propTypes = {
  movie: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  movieIndex: PropTypes.number.isRequired
};
MovieImage.defaultProps = {
  disabled: false
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...movieActions }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps
)(MovieImage);
