const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validEmail = email => email.match(emailRegex);

// consult with backend for a password standard
// const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
export const validPassword = pw => !!(pw && pw.length > 5 && pw.length < 255);

// https://stackoverflow.com/questions/20678300/sweden-phone-number-regular-expression
const phoneNumberRegex = /^([+]46|0)\s*(7[02369])\s*(\d{4})\s*(\d{3})$/;
export const validPhoneNumber = pn => pn.match(phoneNumberRegex);
