import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TextField from "material-ui/TextField";
import CircularProgress from "material-ui/CircularProgress";
import Snackbar from "material-ui/Snackbar";
import Button from "../components/Button";
import { validPassword } from "../utils/validation";

import "../styles/PasswordReset.css";
import * as userActions from "../actions/userActions";

class PasswordReset extends Component {
  state = {
    email: "",
    password: "",
    passwordRepeat: "",
    emailErrorText: "",
    passwordErrorText: "",
    passwordRepeatErrorText: "",
    loading: false,
    serverMessage: ""
  };

  validateAndSend = () => {
    const { password, passwordRepeat } = this.state;
    let error = false;
    const errors = {
      emailErrorText: "",
      passwordErrorText: "",
      passwordRepeatErrorText: ""
    };

    if (!validPassword(password)) {
      error = true;
      errors.passwordErrorText =
        "Ogiltigt lösenord. Ett lösenord måste innehålla minst 6 tecken.";
    }

    if (password !== passwordRepeat) {
      error = true;
      errors.passwordRepeatErrorText = "Både lösenords-fälten måste matcha.";
    }

    this.setState(errors);

    if (!error) {
      this.sendReset();
    }
  };

  sendReset = async () => {
    const token = this.props.match.params.token; // eslint-disable-line
    const email = decodeURIComponent(this.props.match.params.email);
    const { password } = this.state;
    this.props.resetPassword(email, password, token);
  };

  render() {
    return (
      <div className="password-reset-wrapper">
        <h1>Byt lösenord</h1>
        <TextField
          onChange={(evt, val) => this.setState({ password: val })}
          onFocus={() =>
            this.setState({ passwordErrorText: "", serverMessage: "" })
          }
          errorText={this.state.passwordErrorText}
          floatingLabelText="Nytt lösenord"
          type="password"
          fullWidth
        />
        <TextField
          onChange={(evt, val) => this.setState({ passwordRepeat: val })}
          onFocus={() =>
            this.setState({ passwordRepeatErrorText: "", serverMessage: "" })
          }
          errorText={this.state.passwordRepeatErrorText}
          floatingLabelText="Bekräfta nytt lösenord"
          type="password"
          fullWidth
        />
        {this.props.loading ? (
          <CircularProgress size={40} />
        ) : (
          <Button
            style={{ marginLeft: 0, marginTop: 40, width: "100%" }}
            medium
            rounded
            type="tertiary"
            title="Klar "
            onClick={this.validateAndSend}
          />
        )}
        <Snackbar
          message={this.props.message || ""}
          open={!!this.props.message}
          autoHideDuration={4000}
        />
        {this.props.resetSuccess && this.props.history.push("/login")}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.user.error,
    resetSuccess: state.user.resetSuccess,
    loading: state.user.loading,
    message: state.user.message
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset);
