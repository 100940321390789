/* global localStorage */
import * as types from "../constants";
import * as API from "./api";

/* eslint-disable import/prefer-default-export */
export function showMovieDetail(movie) {
  return async dispatch => {
    dispatch({
      type: types.SHOW_MOVIE_DETAIL,
      payload: movie
    });
  };
}
export function playMovie(movie) {
  return async dispatch => {
    dispatch({
      type: types.MOVIE_PLAY,
      payload: movie
    });
  };
}
export function hideMovieDetail() {
  return async dispatch => {
    dispatch({
      type: types.HIDE_MOVIE_DETAIL
    });
  };
}

export function getSignedURL(movie) {
  return dispatch => {
    dispatch({
      type: types.GET_SIGNED_URL
    });
    API.getSignedURL(movie, (err, res) => {
      if (err) {
        return dispatch({
          type: types.GET_SIGNED_URL_FAIL,
          payload: err
        });
      }
      // Parse the url from the response
      const output = JSON.parse(res.Payload);
      if (output.errorMessage) {
        return dispatch({
          type: types.GET_SIGNED_URL_FAIL,
          payload: output.errorMessage
        });
      }
      const url = output;
      dispatch({
        type: types.GET_SIGNED_URL_SUCCESS,
        payload: url
      });
    });
  };
}
export function getMovies(forceSync = false) {
  console.log("Getting movies");
  return async dispatch => {
    dispatch({
      type: types.GET_MOVIES
    });
    try {
      const movies_str = sessionStorage.getItem("movies");
      const movies = JSON.parse(movies_str);
      if (movies && false) {
        return dispatch({
          type: types.GET_MOVIES_SUCCESS,
          payload: movies
        });
      }

      API.getMovies((err, res) => {
        if (err || res.StatusCode !== 200) {
          dispatch({
            type: types.GET_MOVIES_FAIL,
            payload: {
              err
            }
          });
        } else {
          sessionStorage.setItem("movies", res.Payload);
          dispatch({
            type: types.GET_MOVIES_SUCCESS,
            payload: JSON.parse(res.Payload)
          });
        }
      });
    } catch (err) {
      dispatch({
        type: types.GET_MOVIES_FAIL,
        payload: { error: "Server error." }
      });
    }
  };
}
export function updateMovie(newMovie) {
  return async dispatch => {
    dispatch({
      type: types.UPDATE_MOVIE
    });
    try {
      API.updateMovie(newMovie, (err, res) => {
        const payload = !err && JSON.parse(res.Payload);
        if (err || payload.errorMessage) {
          dispatch({
            type: types.UPDATE_MOVIE_FAIL,
            payload: {
              error: err || payload.errorMessage
            }
          });
        } else {
          dispatch({
            type: types.UPDATE_MOVIE_SUCCESS,
            payload: JSON.parse(res.Payload)
          });
        }
      });
    } catch (err) {
      dispatch({
        type: types.GET_MOVIES_FAIL,
        payload: { error: "Server error." }
      });
    }
  };
}
export function deleteMovie(title) {
  return async dispatch => {
    dispatch({
      type: types.DELETE_MOVIE
    });
    try {
      API.deleteMovie(title, (err, res) => {
        const payload = !err && JSON.parse(res.Payload);
        if (err || payload.errorMessage) {
          dispatch({
            type: types.DELETE_MOVIE_FAIL,
            payload: {
              error: err || payload.errorMessage
            }
          });
        } else {
          dispatch({
            type: types.DELETE_MOVIE_SUCCESS,
            payload: JSON.parse(res.Payload)
          });
        }
      });
    } catch (err) {
      dispatch({
        type: types.GET_MOVIES_FAIL,
        payload: { error: "Server error." }
      });
    }
  };
}
