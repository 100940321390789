import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Menu from "material-ui/svg-icons/navigation/menu";
import mainlogo from "../assets/karnelogga.png";
import Button from "./Button";
import * as userActions from "../actions/userActions";

import "../styles/Header.css";
import "../styles/link.css";
import logo from "../assets/logo.png";

const renderButtons = (userGroup, loading) => {
  if (!userGroup) {
    return (
      <div>
        <Link to="/login#title">
          <Button
            title="Logga in"
            className="login-button"
            small
            type={loading ? "disabled" : "tertiary"}
          />
        </Link>
        <Link to="/claimaccount#title">
          <Button
            title="Få Karnevalist-Access!"
            small
            className="login-button"
            type={loading ? "disabled" : "tertiary"}
          />
        </Link>
      </div>
    );
  }
  const isPremium = userGroup.indexOf("premium") > -1;
  const isKarnevalist = userGroup.indexOf("karnevalist") > -1;
  if (isPremium || isKarnevalist) {
    return (
      <div>
        <Link to="/moviedetail/2">
          <Button title="Se Drömmarnas Lund" className="login-button" />
        </Link>
      </div>
    );
  }
  return (
    <div>
      <Link to="premium">
        <Button
          title="Bli premium"
          className="login-button"
          type={loading ? "disabled" : "tertiary"}
        />
      </Link>
    </div>
  );
};

const renderText = name =>
  name ? (
    <div>
      <h1>Välkommen,</h1>
      <h2>{name}</h2>
    </div>
  ) : (
    <div>
      <p>presenterar</p>
      <h1>KarnePLAY</h1>
    </div>
  );

const toggleMobile = () => {
  document.getElementById("MobileMenu").classList.toggle("left");
  document.getElementById("MobileIcon").classList.toggle("left");
  document.getElementById("MobileShadow").classList.toggle("show");
  document.getElementById("KarneLogo").classList.toggle("hide");
};

const Header = ({ email, loggedIn, logout, loading, location, miniheader }) => {
  const emailString = email || window.localStorage.getItem("email");
  const userGroup = loggedIn && window.localStorage.getItem("user_group");

  // No header when watching movies
  if (location.pathname.startsWith("/play")) return null;

  const moviePreview = location.pathname.startsWith("/movie") || miniheader;
  const confirmLogout = () => {
    // eslint-disable-next-line
    if (confirm("Är du säker?")) {
      logout();
    }
  };

  return (
    <header className={`header ${moviePreview ? "miniheader" : "mainheader"}`}>
      <div>
        <a href="https://play.lundakarnevalen.se">
          <img className="logo" id="KarneLogo" src={logo} alt="logo" />
        </a>
        <div className="mobile">
          <Menu
            className="icon"
            id="MobileIcon"
            style={{ color: "white" }}
            onClick={toggleMobile}
          />
          <div id="MobileShadow" onClick={toggleMobile} />
          <nav className="mm" id="MobileMenu">
            <a href="/">
              <li>Hem</li>
            </a>
            <a href="/about#title">
              <li>Om KarnePlay</li>
            </a>
            {emailString ? (
              <a href="/" onClick={confirmLogout}>
                <li>Logga ut</li>
              </a>
            ) : null}
          </nav>
        </div>
        <nav className="menu cl-effect-13">
          <a href="/">
            <li>Hem</li>
          </a>
          <a href="/about#title">
            <li>Om KarnePlay</li>
          </a>
          {emailString ? (
            <a href="/" onClick={confirmLogout}>
              <li>Logga ut</li>
            </a>
          ) : null}
        </nav>
      </div>
      {!moviePreview ? (
        <div className="content">
          <img alt="main logo" src={mainlogo} />
          {renderText(emailString)}
          {renderButtons(userGroup, loading)}
        </div>
      ) : (
        ""
      )}
    </header>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  email: PropTypes.string,
  miniheader: PropTypes.bool
};
Header.defaultProps = {
  miniheader: false,
  loading: false,
  email: ""
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Header)
);
