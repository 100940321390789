import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as userActions from "../actions/userActions";
import * as movieActions from "../actions/movieActions";

import "../styles/App.css";

import Header from "../components/Header";
import Welcome from "../components/Welcome";
import Login from "./Login";
import AdminMovie from "./AdminMovie";
import AdminFile from "./AdminFile";
import Register from "./Register";
import ClaimAccout from "./ClaimAccount";
import MovieDetail from "./MovieDetail";
import Play from "./Play";
import Premium from "./Premium";
import About from "./About";
import Verify from "./Verify";
import PasswordReset from "./PasswordReset";
import ForgotPassword from "./ForgotPassword";
import Settings from "./Settings";
import Karnevalist from "./Karnevalist/Karnevalist";
import KarnevalistVerify from "./KarnevalistVerify";
import ImageGallery from "./ImageGallery";

const checkIfAdmin = user =>
  !!(user && user.roles && user.roles.includes("administrator"));

const App = ({ accessToken, user, logout }) => {
  const calculatedAccessToken =
    accessToken || window.localStorage.getItem("accessToken");
  const email = user ? user.email : localStorage.getItem("email");
  const userGroup = window.localStorage.getItem("user_group");
  const isPremium = userGroup && userGroup.indexOf("premium") > -1;
  const isKarnevalist = userGroup && userGroup.indexOf("karnevalist") > -1;
  // If karnevalist show karnevalist, if premium show Moviedetail,
  // otherwise welcome page
  const startScreen =
    (isKarnevalist && Karnevalist) || (isPremium && MovieDetail) || Welcome;

  const loginExpire = window.localStorage.getItem("login_expires");
  const nowTime = new Date().getTime();
  const loggedIn = !!calculatedAccessToken;

  // Get all GET-parameters in the URL.
  const GET = window.location.search
    .substr(1)
    .split("&")
    .reduce((acc, curr) => {
      const [k, v] = curr.split("=");
      acc[k] = v;
      return acc;
    }, {});

  if (GET.action === "verify" && !!GET.email && !!GET.token) {
    return (
      <div className="App">
        <BrowserRouter>
          <div style={{ height: "100%" }}>
            <Header
              loggedIn={loggedIn}
              admin={checkIfAdmin(user)}
              email={email}
              miniheader
              logout={logout}
            />
            <KarnevalistVerify email={GET.email} token={GET.token} />
          </div>
        </BrowserRouter>
      </div>
    );
  }

  if (loginExpire && loginExpire < nowTime) {
    logout();
  }

  return (
    <div className="App" id="MyApp">
      <BrowserRouter>
        <div style={{ height: "100%" }}>
          <Header
            loggedIn={loggedIn}
            admin={checkIfAdmin(user)}
            email={email}
            miniheader={isPremium || isKarnevalist}
            logout={logout}
          />
          <Switch>
            <Route exact path="/" component={startScreen} />
            <Route
              exact
              path="/login"
              render={
                calculatedAccessToken
                  ? () => <Redirect to="/" />
                  : () => <Login />
              }
            />
            <Route exact path="/register" component={Register} />
            <Route exact path="/claimaccount" component={ClaimAccout} />
            <Route exact path="/admin/movies" component={AdminMovie} />
            <Route exact path="/admin/files" component={AdminFile} />
            <Route exact path="/about" component={About} />
            <Route exact path="/moviedetail/:id" component={MovieDetail} />
            <Route name="play" path="/play/:movie_title" component={Play} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/premium" component={Premium} />
            <Route
              exact
              path="/verify/:email/:verifytoken"
              component={Verify}
            />
            <Route path="/images/:section" component={ImageGallery} />
            <Route path="/reset/:email/:token" component={PasswordReset} />
            <Route path="/karnevalist" component={Karnevalist} />
            <Route
              path="/karnevalistverify/:email/:token"
              component={KarnevalistVerify}
            />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
};

App.propTypes = {
  accessToken: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  user: PropTypes.object,
  logout: PropTypes.func.isRequired
};

App.defaultProps = {
  accessToken: false,
  user: null
};

function mapStateToProps(state) {
  return {
    accessToken: state.user.accessToken,
    user: state.user.user,
    movies: state.movie.movies,
    play_movie: state.movie.play
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions, ...movieActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
